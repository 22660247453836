/* General Styles */
body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #1F5156;
    margin: 0;
    padding: 0;
    background-color: #f4f4f4;
}

.container {
    width: 90%;
    max-width: 1100px;
    margin: 0 auto;
    padding: 20px;
}

.heading {
    color: #1F5156;
    font-size: 36px;
    position: absolute; /* Position the heading at the top */
    top: 0; /* Align it to the top of the page */
    left: 50%; /* Center horizontally */
    transform: translateX(-50%); /* Correct the positioning from left edge */
    text-transform: uppercase;
    font-weight: bold;
    margin: 0; /* Remove default margins */
    padding: 20px 0; /* Add some padding for spacing */
    width: 100%;
    text-align: center; /* Center text */
}

.subheading {
    color: #1F5156;
    font-size: 28px;
    margin-top: 20px;
    margin-bottom: 10px;
}

/* Styling for the content section */
.content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 80px; /* Space for the heading to ensure it doesn't overlap with the content */
}

ul {
    margin-left: 20px;
}

ul li {
    margin-bottom: 10px;
}

/* Responsive Design */
@media (max-width: 768px) {
    .heading {
        font-size: 28px;
    }

    .subheading {
        font-size: 24px;
    }

    .container {
        width: 95%;
    }

    .content {
        padding: 15px;
    }
}

@media (max-width: 480px) {
    .heading {
        font-size: 24px;
    }

    .subheading {
        font-size: 20px;
    }
}
