.div_body{
  background-color: blueviolet;
  height: 100vh;
  width: 100vw;
  background-size: cover;
  background-position: center;
    background-repeat: no-repeat;

}

.section_padding{
  padding: 4rem 4rem;
}

.footer{
  background-color: rgb(250, 250, 250);
  background-size: cover;
  width: 100%;
 }

.sb_footer{
   display: flex;
  flex-direction: column;
}

.sb_footer-links{
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: left;
  width: 100%;
  margin-bottom: 1rem;
  margin-left: -1rem
  
}

.sb_footer-links_div{
  margin: 0.5rem;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  color: black;
 

}

a{
  text-decoration: none;
  color: rgb(104, 102, 100);
}
.socialmedia{
  display: flex;
  flex-direction: row;
}
.socialmedia img{
  width: 80%;
  height: 40px;
  width: 40px;
  margin:  0.5rem;
  filter: drop-shadow( 7px 0.2rem rgba(0, 0, 0, 0.178));
  cursor: pointer; 
}

.sb_footer-links_div p{
  font-size: 12px;
  line-height: 15px;
  margin: 0.5rem 0;
  cursor: pointer;
}

.sb_footer-links_div h4{
  font-size: 14px;
  color: black;
  line-height: 17px;
  margin-bottom: 0.9rem;
}

  .brder{
  width: 80%;

  }

  hr{
    color: black   !important;
    width: 100%;
  }

p{
    text-decoration: none;
    color: rgb(131, 124, 124);
  }

  .font{
    color: black;
  }

  .sb_footer-copyright p {
    font-size: 13px;
    line-height: 15px;
    color: black;
    font-weight: 600;
    text-align: center;
  }
  

  @media screen and (max-width: 850px) {
    .sb_footer.heading h4{
      font-size: 44px;
      line-height: 50px;
      
    }
    .sb_footer-links{
      display: block;
    }
  }

  @media screen and (max-width: 550px) {
    .sb_footer-heading h4{
      font-size: 3px;
      line-height: 42px;
    }

    .sb_footer-links div{
      margin: 1rem 0;
    }

    .sb_footer-links btn p{
      font-size: 14px;
      line-height:  20px;

    }
    .sb_footer-copyright {
   /* background: green; */
      width: 100vw;
      margin-left: -20px;
      color: black;
    }
    .social{
    width: 20vw;
    }

  }
  
  @media screen and (max-width: 400px) {
    .sb_footer-heading h4{
      font-size: 27px;
      line-height: 30px;
    } 
    .sb_footer-links{
      display: block;
    }
    .social{
      width: 20vw;
    }
  }

 
 