/* Add the following styles to Roomcheck.css */

/* Style for the select dropdown */
.input.select {
  -webkit-appearance: none; /* Remove default dropdown arrow in some browsers */
  -moz-appearance: none;
  appearance: none;
  padding: 10px;
  font-size: 16px;
  background-color: #f4f4f4;
  border: 1px solid #ccc;
  width: 100%;
  border-radius: 5px;
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"%3e%3cpath fill="%23aaa" d="M6.293 7.293a1 1 0 011.414 0L10 9.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" /%3e%3c/svg%3e');
  background-position: right 10px center;
  background-repeat: no-repeat;
}

/* Optional: To style the dropdown when clicked */
.input.select:focus {
  border-color: #1f5156;
  outline: none;
}

/* Adjust label and input positioning */
.input-container {
  position: relative;
  margin-bottom: 20px;
}

.input-container select {
  padding: 12px 14px;
  color: black;
}

.input-container label {
  position: absolute;
  top: 10px;
  left: 15px;
  font-size: 14px;
  color: #aaa;
  transition: all 0.3s ease;
}
.input-container .checkOutDate [type="date"]::-webkit-datetime-edit {
  color: transparent; /* Make the default text color transparent */
}
.input-container .checkInDate [type="date"]::-webkit-datetime-edit {
  color: transparent;
}


/* Focus effect on select and input */
.input.select:focus + label,
.input:not(:placeholder-shown) + label {
  top: -8px;
  font-size: 12px;
  color: #1f5156;
}

/* Optional: For the whole form */
.input-container select, 
.input-container input,
.input-container textarea {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.input-container textarea {
  resize: vertical;
  min-height: 100px;

}
